<template>
    <div>

        <NavigationBar
            :currentuser="currentUser"
            @onRequestChanged="onRequestChanged">
        </NavigationBar>

        <Map ref="map" :markers="markers"/>
    </div>
</template>

<script>

import NavigationBar from "./components/NavigationBar";
import Map from "./components/Map";
import {latLng} from "leaflet";
import axios from "axios";
import moment from "moment";

export default {
    name: 'App',
    props: ['params'],
    components: {
        Map,
        NavigationBar
    },

    data() {
        return {
            currentUser: "Unknown",
            markers: []
        }
    },

    methods: {

        onRequestChanged(param) {
            this.getPositions(param);
        },

        getPositions(param) {

            let app = this;

            let startTimestamp = moment(param.startDate).startOf('day').unix();
            let endTimestamp = moment(param.endDate).endOf('day').unix();

            axios.get(`api/positions/${param.user}/${startTimestamp}-${endTimestamp}`)
                .then((response) => {

                    this.markers = response.data.map(position => {

                            let descr = moment(position.moment * 1000).format('LT') + ', '
                                + Math.round(position.speed * 3.6 * 100) / 100 + ' km/h, '
                                + '🔋 ' + position.battery + '%' + ' '
                            ;

                            return {
                                id: position.id,
                                location: latLng(position.lat, position.lng),
                                descr: descr
                            };
                        }
                    );

                    if (this.markers.length > 0) {

                        this.map.fitBounds(this.markers.map(m => {
                            return [m.location.lat, m.location.lng]
                        }));
                    }
                })
                .catch(error => {

                        const message = error?.response?.data?.message

                        if (message) {

                            console.log('Getting positions failed: ' + message);
                            app.showError(message);
                            return;
                        }

                        app.showError(error.message);
                    }
                )
            ;

        },

        showError(message) {
            this.showToast(message, 'danger');
        },

        showToast(message, variant = null) {

            this.$bvToast.toast(message, {
                title: 'Error',
                autoHideDelay: 5000,
                variant: variant,
                toaster: 'b-toaster-bottom-center'
            });
        },
    },

    mounted() {

        this.map = this.$refs.map.$refs.map;
        let paramsData = JSON.parse(this.params);

        this.currentUser = paramsData.user;
        console.log(this.currentUser.id);

        let today = new Date();

        this.getPositions({
            startDate: today,
            endDate: today,
            user: this.currentUser.id,
        });
    }
}

// axios.defaults.withCredentials = true;


// function authorize() {
//
//   axios.get('/sanctum/csrf-cookie').then(response => {
//
//     axios.post('/login', {
//       "email": "yyy@gmail.com",
//       "password": "123",
//     }).catch(error => {
//       console.log(error.response.data);
//       return Promise.reject(error)
//     }).then(response => {
//       getApiData();
//     });
//
//   });
// }

</script>

<style>

</style>
