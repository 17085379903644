<template>

    <div id="map">

        <l-map
            ref="map"
            v-if="showMap"
            :zoom="zoom"
            :options="mapOptions"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate">

            <l-tile-layer
                :url="url"
                :attribution="attribution"></l-tile-layer>

            <l-marker v-for="marker in markers"
                      :key="marker.id"
                      :lat-lng="marker.location">
                <l-tooltip>
                    {{ marker.descr }}
                </l-tooltip>

            </l-marker>

            <l-polyline :lat-lngs="markers.map(m => m.location)"></l-polyline>

        </l-map>

    </div>

</template>

<script>

import {LMap, LTileLayer, LMarker, LTooltip, LIcon, LPolyline} from "vue2-leaflet";
import {Icon} from 'leaflet';


export default {

    name: "Map",

    components: {
        LMap,
        LTileLayer,
        LMarker,
        LTooltip,
        LIcon,
        LPolyline,
    },
    props: ['markers'],

    data() {
        return {
            zoom: 3,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            currentZoom: 11.5,
            mapOptions: {
                zoomSnap: 1
            },
            showMap: true,
        };
    },

    methods: {
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
            this.mapObject
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        innerClick(e) {
            console.log(e)
        },
    },
    mounted() {

        delete Icon.Default.prototype._getIconUrl;

        Icon.Default.mergeOptions({
            iconRetinaUrl: '/images/vendor/leaflet/dist/marker-icon-2x.png',
            iconUrl: '/images/vendor/leaflet/dist/marker-icon.png',
            shadowUrl: '/images/vendor/leaflet/dist/marker-shadow.png',
        });
    }
};

</script>

<style>

#map {
    position: absolute;
    top: 40pt;
    width: 100%;
    bottom: 0;
    z-index: -1;
}

</style>
