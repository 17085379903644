<template>

    <b-form method="post" id="login-form" class="position-absolute mt-lg-5 bg-white p-3 border rounded col-md-4"
            :action="getFormAction()">

        <input type="hidden" name="_token" :value="this.token">

        <b-form-group class="d-flex flex-wrap justify-content-center">
            <b-link href="/"><h1 class="h1">{{ this.appName }}</h1></b-link>
        </b-form-group>

        <b-form-group class="d-flex flex-wrap justify-content-center">

            <b-form-radio-group
                id="btn-radios-2"
                v-model="selected"
                :options="options"
                button-variant="outline-primary"
                size="lg"
                name="radio-btn-outline"
                buttons>

            </b-form-radio-group>
        </b-form-group>

        <b-form-group v-if="isPhoneAuthorization()">

            <b-form-input
                id="phone_number"
                name="phone_number"
                placeholder="+380991112233"
                type="tel"
                required>
            </b-form-input>
        </b-form-group>

        <b-form-group v-if="isEmailAuthorization()">
            <b-form-input
                id="username"
                name="email"
                autocomplete="username"
                placeholder="john@smith.com"
                type="email"
                required>
            </b-form-input>
        </b-form-group>

        <b-form-group v-if="isEmailAuthorization()">
            <b-form-input
                id="password"
                type="password"
                name="password"
                :autocomplete="isRegisterMode() ? 'new-password' : 'current-password'"
                placeholder="Password"
                required>
            </b-form-input>
        </b-form-group>

        <b-form-group v-if="isEmailAuthorization() && isRegisterMode()">
            <b-form-input
                id="password_confirmation"
                type="password"
                name="password_confirmation"
                autocomplete="new-password"
                placeholder="Password"
                required>
            </b-form-input>
        </b-form-group>

        <b-form-group v-if="isRegisterMode()">

            <b-form-input
                id="fullname"
                name="name"
                placeholder="John Smith"
                type="text"
                required>
            </b-form-input>
        </b-form-group>

        <b-form-group>

            <b-form-checkbox
                id="remember_me"
                name="remember"
                value="true"
                unchecked-value="false">
                Remember me
            </b-form-checkbox>
        </b-form-group>

        <b-form-group>
            <b-button
                type="submit"
                class="col"
                variant="primary">
                {{ isRegisterMode() ? 'Register' : 'Log in' }}
            </b-button>
        </b-form-group>

        <ul style="list-style: disc; color: red;">
            <li v-for="error in this.errors">{{ error }}</li>
        </ul>

        <b-form-group class="d-flex justify-content-end">
            <span v-if="isEmailAuthorization()">
            <b-link href="forgot-password">
                Forgot password?
            </b-link>
            or
                </span>
            <b-link href="login" v-if="isRegisterMode()">
                Log in
            </b-link>
            <b-link href="register" v-if="!isRegisterMode()">
                Register
            </b-link>
        </b-form-group>

    </b-form>

</template>

<script>
export default {
    name: "AuthForm",
    props: ['params'],

    data() {
        return {
            selected: 'email',
            options: [
                {text: 'Email', value: 'email'},
                {text: 'Phone', value: 'phone'},
            ],
            errors: [],
            token: "",
            mode: "login",
            appName: "",
        }
    },

    methods: {
        isRegisterMode() {
            return this.mode === 'register'
        },
        isPhoneAuthorization() {
            return this.selected === 'phone'
        },
        isEmailAuthorization() {
            return this.selected === 'email'
        },
        getFormAction() {
            if (this.isRegisterMode()) {
                return this.isEmailAuthorization() ? 'register' : 'register-otp'
            } else {
                return this.isEmailAuthorization() ? 'login' : 'login-otp'
            }
        },
    },

    mounted() {
        let paramsData = JSON.parse(this.params)
        this.errors = paramsData.errors
        this.token = paramsData.token
        this.mode = paramsData.mode
        this.appName = paramsData.appName
    }
}
</script>

<style scoped>


</style>
